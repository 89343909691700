<template>
  <div class="popup_wrap" style="width:1750px;">
    <button class="layer_close" @click.prevent="layerClose()">close</button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('cp.CTRL020P160.001') }}</h1>
      <div class="content_box mt10">
        <div class="cp-popinfo">
          <div>
            <span>{{ $t('cp.CTRL020P160.002') }}</span>
            <span>{{ parentInfo.sfOpt.scenarioNm }}</span>
          </div>
          <div>
            <span>{{ $t('cp.CTRL020P160.003') }}</span>
            <span>{{ parentInfo.sfOpt.effectTo }}</span>
          </div>
          <div>
            <span>{{ $t('cp.CTRL020P160.004') }}</span>
            <span>{{ $t('cp.CTRL020P160.005') }}</span>
          </div>
          <div></div>
        </div>
      </div>
      <div class="content_box cp-parentnode">
        <div id="realgrid" style="width: 100%; height: 482px" />
      </div>
      <div class="text_center mt10">
        <a class="button blue lg" href="#" @click.prevent="asyncAlert($t('cp.CTRL020P160.006'))">{{ $t('cp.COMMON.011') }}</a>
        <a class="button blue lg" href="#" @click.prevent="layerClose()">{{ $t('cp.COMMON.007') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
  import scenarioTargetSet from '@/api/rest/cp/scenarioTargetSet'
  import { GridView, LocalDataProvider } from 'realgrid'

  let gridView = GridView
  let provider = LocalDataProvider

  const fields = [
    { fieldName: 'teuTgt', dataType: 'number', subType: 'uint' },
    { fieldName: 'revTeuTgt', dataType: 'number', subType: 'uint' },
    { fieldName: 'cm1TeuTgt', dataType: 'number', subType: 'uint' }
  ]

  // https://docs.realgrid.com/refs/number-cell-editor
  const columns = [
    { name: 'teuTgt', fieldName: 'teuTgt', header: { text: app.$t('cp.CTRL020P160.007'), styleName: 'cp-tgt-header' }, width: 100, numberFormat: '0', editable: true, editor: { type: 'number', positiveOnly: true, integerOnly: true }, styleName: 'cp-tgt-column' },
    { name: 'revTeuTgt', fieldName: 'revTeuTgt', header: { text: app.$t('cp.CTRL020P160.008'), styleName: 'cp-tgt-header' }, width: 100, numberFormat: '0', editable: true, editor: { type: 'number', positiveOnly: true, integerOnly: true }, styleName: 'cp-tgt-column' },
    { name: 'cm1TeuTgt', fieldName: 'cm1TeuTgt', header: { text: app.$t('cp.CTRL020P160.009'), styleName: 'cp-tgt-header' }, width: 100, numberFormat: '0', editable: true, editor: { type: 'number', positiveOnly: true, integerOnly: true }, styleName: 'cp-tgt-column' }
  ]

  const layout = [
    {
      name: 'opprTgt',
      direction: 'horizontal',
      items: [
        'teuTgt',
        'revTeuTgt',
        'cm1TeuTgt'
      ],
      header: {
        text: app.$t('cp.CTRL020P160.010'),
        styleName: 'cp-tgt-header'
      }
    }
  ]

  export default {
    name: 'SfOpportunityPop',
    components: {
    },
    props: {
      parentInfo: {
        type: Object,
        default: function () {
          return {
          }
        }
      }
    },
    data: function () {
      return {
      }
    },
    mounted () {
      const $vm = this

      provider = new LocalDataProvider(true)
      gridView = new GridView('realgrid')
      gridView.setDataSource(provider)
      gridView.header.heights = [22, 23]
      gridView.header.showTooltip = false
      gridView.footers.visible = false
      gridView.setDisplayOptions({
        rowHeight: 35,
        fitStyle: 'even',
        hscrollBar: true,
        selectionStyle: 'block',
        columnMovable: false
      })
      gridView.setStateBar({
        visible: true
      })
      gridView.setRowIndicator({
        visible: false
      })
      gridView.setCheckBar({
        visible: false
      })
      gridView.setSortingOptions({
        textCase: 'insensitive',
        keepFocusedRow: true,
        style: 'inclusive',
        showSortOrder: true
      })
      gridView.setFilteringOptions({
        enabled: false
      })
      gridView.setEditOptions({
        commitByCell: true
      })
      gridView.setPasteOptions({
        checkReadOnly: true,
        enableAppend: false
      })

      gridView.onEditCommit = (_grid, _index, _oldValue, newValue) => {
        if (['', null, undefined].includes(newValue)) {
          return false
        }
      }
      gridView.onCellPasting = (_grid, _index, value) => {
        if (['', null, undefined].includes(value) || Number.isNaN(value)) {
          return false
        }
      }

      // 그리드 정보 전처리
      $vm.setInfo()

      // 그리드 필드 생성
      provider.setFields([...$vm.parentInfo.sfOpt.fields, ...fields])
      gridView.setColumns([...$vm.parentInfo.sfOpt.columns, ...columns])

      // 컬럼 순서 조정
      const refColumnIdx = $vm.parentInfo.sfOpt.columnLayout.map(elem => elem.column).indexOf('salUnoBs') + 1
      gridView.setColumnLayout([...$vm.parentInfo.sfOpt.columnLayout.slice(0, refColumnIdx), ...layout, ...$vm.parentInfo.sfOpt.columnLayout.slice(refColumnIdx)])

      // 데이터 처리 및 그리드 생성
      $vm.setGridList()
    },
    methods: {
      setInfo () {
        const renameMapper = {
          porCtr: this.$t('cp.CTRL020P160.011'),
          porPlc: this.$t('cp.CTRL020P160.012'),
          dlyCtr: this.$t('cp.CTRL020P160.013'),
          dlyPlc: this.$t('cp.CTRL020P160.014'),
          salUnoBs: this.$t('cp.CTRL020P160.015')
        }

        for (const row of this.parentInfo.sfOpt.columns) {
          if (Object.keys(renameMapper).includes(row.name)) {
            row.visible = true
            row.header.text = renameMapper[row.name]
            row.header.template = renameMapper[row.name]
          }
        }
      },
      setGridList () {
        const gridList = this.parentInfo.sfOpt.gridList.map(row => Object.assign({}, row, { teuTgt: 0, revTeuTgt: 0, cm1TeuTgt: 0 }))
        provider.setRows(gridList)
      },
      async runOpportunity () {
        const opportunityList = gridView.getJsonRows(0, -1).map(row => ({
          scenarioCd: this.parentInfo.sfOpt.groupKeyCheck.scenarioCd,
          porCtr: row.porCtr,
          porPlc: row.porPlc,
          dlyCtr: row.dlyCtr,
          dlyPlc: row.dlyPlc,
          bkgShipper: row.bkgShipper,
          teuTgt: row.teuTgt,
          revTeuTgt: row.revTeuTgt,
          cm1TeuTgt: row.cm1TeuTgt
        }))

        await scenarioTargetSet.salesforceOpportunity(opportunityList).then(response => {
          switch (response.data) {
            case 'OK': {
              this.openAlert(this.$t('cp.CTRL020P160.016'))
              break
            }
            case 'NO': {
              this.openAlert(this.$t('cp.CTRL020P160.017'))
              break
            }
            case 'ING': {
              this.openAlert(this.$t('cp.CTRL020P160.018'))
              break
            }
            default: {
              const sfErrorContainer = document.createElement('div')
              sfErrorContainer.setAttribute('class', 'cp-inner-container cp-scroll cp-scroll--auto mt10')
              const txt = document.createTextNode(response.data)
              sfErrorContainer.appendChild(txt)
              const msg = `${this.$t('cp.CTRL020P160.019')}<br>${sfErrorContainer.outerHTML}`
              this.openAlert(msg)
              break
            }
          }
        }).catch(err => {
          console.error(err)
        })
      },
      layerClose () {
        this.$emit('close')
        $('.popup_dim').fadeOut()
        $('body,html').removeAttr('style')
        return false
      },
      openAlert (msg) {
        let obj = {
          alertType: 'simple',
          onClose: this.closeFun,
          customCloseBtnClass: 'button blue lg simplert-test1',
          customCloseBtnText: this.$t('cp.COMMON.005'),
          useIcon: false,
          customClass: 'simple_alert',
          message: msg,
          type: 'info'
        }
        this.$ekmtcCommon.alert(obj)
      },
      async asyncAlert (msg) {
        let obj = {
          alertType: 'normal',
          showXclose: true,
          customCloseBtnClass: 'button blue lg',
          customCloseBtnText: this.$t('cp.COMMON.007'),
          customConfirmBtnText: this.$t('cp.COMMON.005'),
          customConfirmBtnClass: 'button blue lg',
          useConfirmBtn: true,
          title: this.$t('cp.CTRL020P160.020'),
          message: msg,
          type: 'info'
        }
        gridView.commit()
        if (await this.$ekmtcCommon.asyncAlert(obj)) {
          this.runOpportunity()
        }
      }
    }
  }
</script>
<style>
  .cp-popinfo {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .cp-popinfo > div > span {
    display: inline-block;
  }

  .cp-popinfo > div > span:first-child {
    color: #075bb9;
    font-weight: 500;
    margin-right: 30px;
  }
</style>
